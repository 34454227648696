.header {
    height: 7vh;
    padding-right: 20px;
    padding-left: 20px;
    display: flex;
    top: 0;
    width: 100%;
    justify-content: space-between;
    background-color: #f3f3f3;
    align-items: center;

}

.logout-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    outline: none;
    border: none;
    font-size: larger;
    cursor: pointer;
    border: 1px solid #bfbfbf;
    color: #6D6A69;
    border-radius: 3px;
}
