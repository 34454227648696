.form {
    color: black;
    font-weight: 200;
    border: 1px solid #ddd;
    background-color: #fff;
    div {
        padding: 10px;
    }
}


.toolbox {
    position: absolute;
    top: 3%;
    height: 70px;
    width: 100%;
    right: 2rem;
    display: flex;
    justify-content: space-between;
    padding: 1rem 3rem;
}

.admin-container {
    align-items: center;
    position: relative;
}

.table-wrapper {
    position: absolute;
    top: 15%;
}

.refresh-btn {
    border: 0;
    outline: none;
    font-size: 50px;
    background-color: transparent;
    color: #3276b1;
    cursor: pointer;
}
