.radio-btn-wrapper {
	display: flex;
	flex-direction: row;
	padding: 4px;
}

.env-radio-btn {
	opacity: 0;
	width: 0;
	height: 0;
}

.env-radio-btn:checked + label {
	background-color: #3276b1;
	color: #fff;
}
.env-radio-btn-disabled {
  opacity: 0;
	width: 0;
	height: 0;
  background-color: #fff;
  border: #bfbfbf;
}

.env-radio-btn-label {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto;
	border-radius: 4px;
	cursor: pointer;
	height: 32px;
	width: 96px;
	background-color: #bfbfbf;
	text-align: center;
}

.app-radio-btn-label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  width: 100%;
  height: 36px;
  padding: 4px 4px 4px 12px;
  background-color: #bfbfbf;
  margin-bottom: 1px;
}

.app-radio-btn:checked + label {
  color: #EAEAEA;
  background-color: #5F895F;
}

.app-radio-btn-label:hover {
  background-color: #EAEAEA;
}

.app-radio-btn {
  opacity: 0;
	width: 0;
	height: 0;
}

.radio-btn-label {
  width: 100%;
  padding: 5px 15px;
  cursor: pointer;

  &:hover {
    background-color: lightgray;
  }
}
