.container {
	display: flex;
	flex-wrap: wrap;
}
.main {
	height: calc(100vh - 48px);
	width: calc(100vw - 224px);
	display: flex;
	overflow-y: auto;
  padding: 3rem;
  background-color: #efefef;
}
