.git-container {
    width: 100%;
    .config-wrapper {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        row-gap: 20px;
        margin-bottom: 20px;
        padding-left: 15%;
        padding-right: 15%;
    }
}

.radio-list {
    padding-left: 2rem;
    padding-bottom: 15px;
}

.actions-footer {
    display: flex;
    margin-left: 15%;
    gap: 20px;
}
