.move-btns {
	position: absolute;
	bottom: 3%;
	left: 47.5%;
	display: flex;
	flex-wrap: wrap;
	min-width: 70px;
	align-items: center;
	flex-direction: column;
}

.move-btn {
	border: none;
	outline: none;
	color: #c79121;
	font-size: 2rem;
	background-color: transparent;
	cursor: pointer;
}

.move-btn:hover {
	color: #a90329;
}

.tool-tip {
	font-size: small;
	visibility: hidden;
	width: 120px;
	background-color: black;
	color: #fff;
	text-align: center;
	padding: 5px 0;

	/* Position the tooltip text - see examples below! */
  bottom: 100%;

  margin-left: -60px;
}

.move-btn:hover .tool-tip {
	visibility: visible;
}
