// :root {
//   --main-bg-color: #f9f9f9;
//   --main-color: #555;
//   --error-color: #e63946;
// }

// body {
//   margin: 0;
//   padding: 0;
//   box-sizing: border-box;
//   font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
//   height: 100vh;
//   background-color: var(--main-bg-color);
//   color: var(--main-color);
// }

.btn {
  // text-align: center;
  // vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 4px 10px;
  font-size: 13px;
  border-radius: 2px;
  user-select: none;
}

.btn-danger {
  color: #fff;
  background-color: #a90329;
  border-color: #900323;
}
.btn-danger:hover {
  background-color: #7c0909;
  border-color: #720e0e;
}

// .btn-warning {
//   color: #fff;
//   background-color: #c79121;
//   border-color: #b1811d;
// }

// .btn-warning:hover,
// .btn-warning.active {
//   color: #ffffff;
//   background-color: #a4781b;
//   border-color: #7d5b15;
// }

.btn-success {
  color: #ffffff;
  background-color: #739e73;
  border-color: #659265;
}
.btn-success.active,
.btn-success:hover {
  color: #ffffff;
  background-color: #5f895f;
  border-color: #4c6e4c;
}

// .btn-primary {
//   color: #ffffff;
//   background-color: #3276b1;
//   border-color: #2c699d;
//   border-radius: 2px;
//   &:hover {
//     background-color: #2c699d;
//   }
// }

// .btn:disabled {
//   opacity: 0.5;
// }

// .btn-success_disabled {
//   background-color: #739e73;
//   border-color: #659265;
//   cursor: wait;
// }

.btn-primary {
  // width: 300px;
  color: #ffffff;
  background-color: #3276b1;
  border-color: #2c699d;
}
.btn-primary:hover,
// .btn-primary:focus,
// .btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  color: #ffffff;
  background-color: #296191;
  border-color: #1f496d;
}

// .spinner-wrapper {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .flex-wrap {
//   display: flex;
//   flex-wrap: wrap;
// }

// .non-list-style {
//   list-style: none;
// }

// .none {
//   display: none;
// }

// .spinner-wrapper {
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .full-height {
//   height: 93vh;
// }

// .full-width {
//   width: calc(93vw - 200px);
// }

// table {
//   border-spacing: 0;
//   border: 1px solid #ededed;
// }
// table tr:last-child td {
//   border-bottom: 0;
// }
// table th,
// table td {
//   margin: 0;
//   padding: 0.5rem;
//   border-bottom: 1px solid #ededed;
//   border-right: 1px solid #ededed;
//   position: relative;
// }
// table th:last-child,
// table td:last-child {
//   border-right: 0;
// }
// table tr:nth-child(even) {
//   background-color: #fafafa;
// }

// table th::before {
//   position: absolute;
//   right: 15px;
//   top: 16px;
//   content: "";
//   width: 0;
//   height: 0;
//   border-left: 5px solid transparent;
//   border-right: 5px solid transparent;
// }
// table th.sort-asc::before {
//   border-bottom: 5px solid #22543d;
// }
// table th.sort-desc::before {
//   border-top: 5px solid #22543d;
// }

.error {
  font-size: .7rem;
  font-style: italic;
  color: var(--error-color);
}

// .warning-icon {
//   color: #c09853;
// }

// .form-header {
//   min-height: 55px;
//   background: rgba(248,248,248,.9);
//   border-bottom: 1px solid rgba(0,0,0,.2);
//   span {
//       padding-left: 10px
//   }

// }

// .form-content {
//   min-height: 190px;
// }

.form-group {
  display: flex;
  flex-direction: column;
  height: 70px;
  margin-top: 6px;
  label {
      padding-bottom: 3px;
      font-weight: 300;
      font-size: 13px;
  }
  input:focus {
      outline: 1px solid rgb(71, 71, 143);
  }
}

.input {
  background-color: #fff;
  padding: 6px 6px;
  border: 1px solid rgba(0,0,0,.2);
  outline: 1px solid transparent;
}

// .form-footer {
//   min-height: 65px;
//   background: rgba(248,248,248,.9);
//   border-top: 1px solid rgba(0,0,0,.2);
//   display: flex;
//   justify-content: flex-end;

// }

.input-error {
  outline: 1px solid #e63946;
}

// .modal {
//     top: 20vh;
//     left: 30%;
//     width: 30%;
//     background-color: white;
//     @media (min-width: 768px) {
//       .modal {
//         width: 40rem;
//         left: calc(50% - 20rem);
//       }
//     }
//     @keyframes slide-down {
//       from {
//         opacity: 0;
//         transform: translateY(-3rem);
//       }
//       to {
//         opacity: 1;
//         transform: translateY(0);
//       }
//     }
// }
