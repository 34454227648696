
.confirm-dialog {
  position: fixed;
  top: 30%;
  left: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  animation: slide-down 300ms ease-out forwards;
  height: 150px;
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: space-evenly;
  background-color: rgba(0,0,0,.8);
}

.dialog-details {
    display: flex;
    flex-direction: column;
    color: #ffff;
    h2 {
        font-weight: 300;
    }
    p {
        font-size: 14px;
    }
}

.actions-footer {
    display: flex;
    width: 170px;
    justify-content: space-evenly;
    align-items: flex-end;
}
