.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.75);
  }
  .modal {
    position: fixed;
    top: 20%;
    left: 40%;
    border-radius: 8px;
    background-color: #FEFEFE;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 30;
    animation: slide-down 300ms ease-out forwards;
    min-width: 400px;
  }
